<template>
  <form @submit.prevent.stop="save" class="row q-gutter-lg" v-if="formFields">
    <div class="col">
      <div class="q-mb-sm bloc-item">
        <h6 class="title-h6">{{$t('processes.agent')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="row collapsible-div">
          <AgentCard class="col-6" icon="accessibility" :showSeparator="false" v-if="linkedAgent" :agent="linkedAgent" @menuClick="onMenuClick" />
          <div class="add-agent-card col-6" v-else>
            <search-box v-if="searchBoxActivated"
              @input="onSelectLinkedObj"
              model_type="agent"
              :label="$t('processes.fields.agent.label')"
              :params="{ 'with_virtual_objects': true, 'with_deleted_objects': true }"
              :color="color" />
            <base-add-button v-else :label="$t('processes.add_agent')" :color="color" @click="searchBoxActivated = true" />
          </div>
        </div>
      </div>
      <div class="bloc-item">
        <h6 class="title-h6">{{$t('processes.linked_wallets')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div">
          <div class="row q-col-gutter-md q-mb-md">
            <add-a-wallet v-if="currentSuiviDesDepartsProcess && currentSuiviDesDepartsProcess.wallets" @walletsUpdated="updateWallet" v-model="currentSuiviDesDepartsProcess.wallets" :wallets="currentSuiviDesDepartsProcess.wallets" class="col"/>
            <add-a-wallet v-else @walletsUpdated="updateWallet" class="col"/>
          </div>
          <q-card flat bordered class="q-pa-sm collapsible-div">
            <q-card-section class="q-gutter-sm justify-center">
              <base-input-date class="q-px-sm row" v-bind="formInputProps('date_depart')" :color="color"
                v-model="formData.date_depart" ref="label" />
              <div class="row q-gutter-sm">
                <base-input-select class="col q-pa-none q-mt-none" v-bind="formInputProps('motif')" :color="color"
                  v-model="formData.motif" ref="label" />
                <base-input-date class="col" v-bind="formInputProps('date_courrier')" :color="color"
                  v-model="formData.date_courrier" ref="label" />
              </div>
            </q-card-section>
          </q-card>
        </div>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.linked_suivi_des_ic')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row">
          <div v-if="relatedSuiviIc.length" class="row full-width q-col-gutter-md q-pt-sm">
            <div class="col-12" v-for="suiviIc in relatedSuiviIc" :key="suiviIc.id">
              <base-card @menuClick="onMenuClick" class="col-12" :actions="suiviIc.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label @click="$router.push({ name: 'suivi-des-ic-form', params: { id: suiviIc.id } })" class="cursor-pointer q-mb-md"><strong>{{$t('processes.suivi_des_ic')}}</strong></q-item-label>
                <div class="row">
                  <div class="col" v-for="obj in suiviIc.wallets" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="account_balance_wallet" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.NOMPTF}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-for="obj in suiviIc.agents" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="accessibility" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.TITULAIRE}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </base-card>
            </div>
          </div>
          <div v-else class="col-6" style="height:80px">
            <base-add-button :label="$t('processes.create_linked_suivi_des_ic')" :color="color" @click="createLinkedProcess('suiviIc')" />
          </div>
        </div>
      </div>
      <div class="bloc-item q-mb-sm q-mt-md q-pt-xl">
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <div class="row q-gutter-sm">
              <base-input-select class="col q-pa-none q-mt-none" v-bind="formInputProps('statut_compte_fin_gestion_initial')" :color="color"
                v-model="formData.statut_compte_fin_gestion_initial" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_initialisation_CFG')" :color="color"
                v-model="formData.date_initialisation_CFG" ref="label" />
            </div>
            <div class="row">
              <div class="col-6 q-ml-sm">
                <base-input-date class="" v-bind="formInputProps('date_finalisation_CFG')" :color="color"
                  v-model="formData.date_finalisation_CFG" ref="label" />
              </div>
            </div>
            <div class="row q-gutter-sm q-mt-sm">
              <base-input-select class="col q-pa-none q-mt-none" :options="booleanOption" v-bind="formInputProps('usage_droit_presentation')" :color="color"
                v-model="formData.usage_droit_presentation" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_notification_droit_presentation')" :color="color"
                v-model="formData.date_notification_droit_presentation" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_radiation_ORIAS')" :color="color"
                v-model="formData.date_radiation_ORIAS" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_radiation_CAVAMAC')" :color="color"
                v-model="formData.date_radiation_CAVAMAC" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <base-input-date class="col" v-bind="formInputProps('date_mise_a_jour_ICX')" :color="color"
                v-model="formData.date_mise_a_jour_ICX" ref="label" />
              <base-input-date class="col" v-bind="formInputProps('date_avis_service')" :color="color"
                v-model="formData.date_avis_service" ref="label" />
            </div>
            <div class="row q-gutter-sm">
              <div class="col q-my-none">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.acces_recrutement.label')}}</q-item-label>
                <q-toggle class="" v-model="formData['acces_recrutement']" ref="is_red"
                  :color="color"
                />
              </div>
              <div class="col q-my-none">
                <q-item-label class="q-pt-sm q-pl-sm">{{$t('processes.fields.acces_transmission.label')}}</q-item-label>
                <q-toggle class="" v-model="formData['acces_transmission']" ref="is_red"
                  :color="color"
                />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.date_analyse_portefeuille')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <base-input-date class="row" v-bind="formInputProps('date_initialisation_process_analyse_portefeuille')" :color="color"
              v-model="formData.date_initialisation_process_analyse_portefeuille" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.dossierDestinationTransmission')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <q-card flat bordered class="q-pa-sm collapsible-div">
          <q-card-section class="q-gutter-sm justify-center">
            <base-input-date class="row" v-bind="formInputProps('date_finalisation_dossier_destination_transmission')" :color="color"
              v-model="formData.date_finalisation_dossier_destination_transmission" ref="label" />
          </q-card-section>
        </q-card>
      </div>
      <div class="bloc-item q-mb-sm">
        <h6 class="title-h6">{{$t('processes.linked_suivi_de_recrutement')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="collapsible-div row">
          <div v-if="relatedSuiviDeRecrutement.length" class="row full-width q-col-gutter-md q-pt-sm">
            <div class="col-12" v-for="suiviDeRecrutement in relatedSuiviDeRecrutement" :key="suiviDeRecrutement.id">
              <base-card @menuClick="onMenuClick" class="col-12" :actions="suiviDeRecrutement.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="playlist_add_check" />
                <q-item-label @click="$router.push({ name: 'suivi-de-recrutement-form', params: { id: suiviDeRecrutement.id } })" class="cursor-pointer q-mb-md"><strong>{{$t('processes.suivi_de_recrutement')}}</strong></q-item-label>
                <div class="row">
                  <div class="col" v-for="obj in suiviDeRecrutement.wallets" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="account_balance_wallet" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.NOMPTF}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
                <div class="row">
                  <div class="col" v-for="obj in suiviDeRecrutement.agents" :key="obj.id">
                    <q-item class="q-pa-none">
                      <q-item-section avatar>
                        <q-avatar icon="accessibility" size="2rem" color="reseau" />
                      </q-item-section>
                      <q-item-section>
                        <q-item-label caption>{{obj.TITULAIRE}}</q-item-label>
                      </q-item-section>
                    </q-item>
                  </div>
                </div>
              </base-card>
            </div>
          </div>
          <div v-else class="col-6" style="height:80px">
            <base-add-button :label="$t('processes.create_linked_suivi_de_recrutement')" :color="color" @click="createLinkedProcess('recrutement')" />
          </div>
        </div>
      </div>
      <div class="bloc-item ">
        <h6>{{$t('processes.links')}}</h6>
        <div class="toggler-collapse" @click="toggleCollapsible">
          <div class="arrow"></div>
        </div>
        <div class="row q-gutter-md collapsible-div ">
          <div v-if="relatedLinks.length" class="column col col-6 q-py-md q-col-gutter-md">
            <div class="row q-gutter-sm" v-for="relatedLink in relatedLinks" :key="relatedLink.id">
              <base-card :showSeparator="false" @menuClick="onMenuClick" class="col-12" :actions="relatedLink.actions">
                <q-avatar slot='avatar' size="3rem" :color="color" text-color="white" icon="link" />
                <q-item-label class="q-mb-md"><strong>{{relatedLink.titre}}</strong></q-item-label>
                <a :href="relatedLink.lien" target="_blank"><q-item-label :class="`text-${color}`">{{relatedLink.lien}}</q-item-label></a>
              </base-card>
            </div>
          </div>

          <div v-if="!isLinksFormOpen" class="col-6" style="height:81px">
            <base-add-button :label="$t('sujets.add_link')" :color="color" @click="isLinksFormOpen = true" />
          </div>

          <div v-else class="col-6">
            <q-card flat bordered class="q-pa-md">
              <base-input-text v-bind="titleTemp" :color="color"
                  v-model="link.titre" ref="admin_note" />
              <base-input-text v-bind="linkTemp" :color="color"
                  v-model="link.lien" ref="admin_note" />
              <q-btn :color="color" size="sm" class="float-right" unelevated @click="addLink()">{{$t('form.ok')}}</q-btn>
              <div style="clear: both;"></div>
            </q-card>
          </div>
        </div>
      </div>
    </div>

    <div class="col col-2 q-gutter-y-sm">
      <q-list class="full-width">
        <q-item class="q-pa-none">
          <base-input-select v-bind="formInputProps('statut')" :color="color"
            v-model="formData.statut" ref="statut" />
      </q-item>
      </q-list>

      <div class="q-gutter-y-sm">
        <q-btn :color="color" class="full-width" size="sm" outline @click.native="destroy" :disabled="!isUpdate">{{$t('form.delete')}}</q-btn>
        <q-btn :color="color" class="full-width" size="sm" unelevated type="submit" :disabled="!hasChanged">{{$t('form.submit')}}</q-btn>
        <q-btn :color="color" class="full-width q-py-md" size="sm" @click="finishProcess">{{$t('form.finish')}}</q-btn>
      </div>

    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import { BaseForm } from '../../../mixins'
import addAWallet from '../shared/addAWallet'
import { AgentCard } from '../../../components/agents'

export default {
  mixins: [BaseForm],
  components: { addAWallet, AgentCard },
  props: {
    'form_model': { type: String, default: 'processes' },
    'action': { type: String, default: 'processes/saveProcesses' }
  },

  data () {
    return {
      filter: {},
      loading: false,
      isFormOpen: false,
      linkedAgent: null,
      searchBoxActivated: false,
      isUpdate: this.$route.params.id,
      booleanOption: [
        { value: true, label: 'Oui' },
        { value: false, label: 'Non' }
      ],
      // Related processes
      relatedProcessLinked: [],
      // For links
      relatedLinks: [],
      isLinksFormOpen: false,
      titleTemp: { label: 'Titre du lien' },
      linkTemp: { label: 'Chemin réseau' },
      link: {
        titre: '',
        lien: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      color: 'pages/getColor',
      isAdmin: 'auth/isAdmin',
      currentSuiviDesDepartsProcess: 'processes/getCurrentProcess',
      rowsPerPageOptions: 'config/getRowsPerPageOptions'
    }),

    relatedSuiviIc: {
      get () {
        return this.relatedProcessLinked.length ? this.relatedProcessLinked.filter(process => process.type === 'suiviIc') : []
      },
      set (newVal) {
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'suiviIc')
        this.formData.related_objects.push(...newVal.map(suiviIc => ({ model_type: 'suiviIc', model_id: suiviIc.id })))
        this.relatedProcessLinked = this.relatedProcessLinked.filter(process => process.type !== 'suiviIc')
        this.relatedProcessLinked.push(...newVal)
      }
    },
    relatedSuiviDeRecrutement: {
      get () {
        return this.relatedProcessLinked.length ? this.relatedProcessLinked.filter(process => process.type === 'recrutement') : []
      },
      set (newVal) {
        this.formData.related_objects = this.formData.related_objects.filter(relObj => relObj.model_type !== 'recrutement')
        this.formData.related_objects.push(...newVal.map(suiviRecrutement => ({ model_type: 'recrutement', model_id: suiviRecrutement.id })))
        this.relatedProcessLinked = this.relatedProcessLinked.filter(process => process.type !== 'recrutement')
        this.relatedProcessLinked.push(...newVal)
      }
    }
  },

  watch: {
    formFields: {
      handler (newVal, oldVal) {
        this.formData.model_type = 'depart'
        if (!newVal || !this.currentSuiviDesDepartsProcess) return

        this.formData = {
          ...this.formData,
          ...newVal.map(({ id, options, type, boolean }) => ({ id, options, type, boolean }))
            .reduce((acc, { id, options, type, boolean }) => ({
              ...acc,
              [id]: options
                ? options.find(({ value }) => value === this.currentSuiviDesDepartsProcess[id])
                : type === 'select' && boolean
                  ? this.booleanOption.find(val => val.value === this.currentSuiviDesDepartsProcess[id])
                  : this.currentSuiviDesDepartsProcess[id]
            }), {})
        }

        this.formData.related_objects = []
        if (this.$route.params.id) {
          this.formData.id = this.$route.params.id

          // Set wallets data
          let walletsMapped = this.currentSuiviDesDepartsProcess.wallets.map(wallet => ({
            model_type: 'wallet',
            model_id: wallet.id
          }))
          this.formData.related_objects.push(...walletsMapped)

          // Set agent
          if (this.currentSuiviDesDepartsProcess.agents) {
            this.linkedAgent = this.currentSuiviDesDepartsProcess.agents.map(agent => ({
              ...agent,
              label: agent['TITULAIRE'],
              actions: [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: agent.id } } }],
              is_favorite: !!this.favorites && !!this.favorites['agent'] &&
                this.favorites['agent'].some(favorite => favorite.id === agent.id),
              model: { model_type: 'agent', model_id: agent.id }
            }))[0]
          }
          // Set process suivi IC lié
          if (this.currentSuiviDesDepartsProcess.suivi_ics) {
            let suiviIcFormDataMapped = this.currentSuiviDesDepartsProcess.suivi_ics.map(suiviIc => ({
              model_type: 'suiviIc',
              model_id: suiviIc.id
            }))
            this.formData.related_objects.push(...suiviIcFormDataMapped)

            let suiviIcLinkedMapped = this.currentSuiviDesDepartsProcess.suivi_ics.map(suiviIc => ({
              type: 'suiviIc',
              id: suiviIc.id,
              wallets: suiviIc.wallets,
              agents: suiviIc.agents,
              actions: [
                { label: this.$t('processes.unlink_suiviIc'), payload: { name: 'unlink-suiviIc', params: { id: suiviIc.id } } }
              ]
            }))
            this.relatedProcessLinked.push(...suiviIcLinkedMapped)
          }
          // Set process recrutement lié
          if (this.currentSuiviDesDepartsProcess.recrutements) {
            let suiviDeRecrutementFormDataMapped = this.currentSuiviDesDepartsProcess.recrutements.map(suiviDeRecrutement => ({
              model_type: 'recrutement',
              model_id: suiviDeRecrutement.id
            }))
            this.formData.related_objects.push(...suiviDeRecrutementFormDataMapped)

            let suiviDeRecrutementLinkedMapped = this.currentSuiviDesDepartsProcess.recrutements.map(suiviDeRecrutement => ({
              type: 'recrutement',
              id: suiviDeRecrutement.id,
              wallets: suiviDeRecrutement.wallets,
              agents: suiviDeRecrutement.agents,
              actions: [
                { label: this.$t('processes.unlink_recrutement'), payload: { name: 'unlink-recrutement', params: { id: suiviDeRecrutement.id } } }
              ]
            }))
            this.relatedProcessLinked.push(...suiviDeRecrutementLinkedMapped)
          }
          // Set links
          this.relatedLinks = this.currentSuiviDesDepartsProcess.liens ? JSON.parse(this.currentSuiviDesDepartsProcess.liens).map(relatedLink => {
            return {
              ...relatedLink,
              actions: [
                { label: this.$t('sujets.delete_linked_link'), payload: { name: 'delete-link', params: { id: relatedLink.id } } }
              ]
            }
          }) : []
          this.formFields.find(form => form.id === 'liens').type = 'text'
          console.log(this.formFields)
          this.formData.liens = null
        }
      },
      immediate: true
    },
    linkedAgent (newVal, oldVal) {
      if (!this.formData.related_objects) this.formData.related_objects = []
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'agent')
      if (newVal) this.formData.related_objects.push({ model_type: 'agent', model_id: newVal.id })
    },
    relatedLinks (newVal, oldVal) {
      this.formData.liens = newVal.length > 0 ? newVal.filter(lien => lien.id !== null).map(({ titre, lien, id }) => ({ titre, lien, id })) : []
    }
  },

  mounted () {
    this.$store.commit('pages/setBreadcrumbs', [
      { label: this.$t('global.home'), to: { name: 'home' }, icon: 'home' },
      { label: this.$t('processes.processAgence'), to: { name: '' } },
      { label: this.$t('processes.title.suivi_des_departs'), to: { name: 'suivi-des-departs-en-cours' } },
      this.$route.params.id ? { label: `fiche #${this.$route.params.id}`, to: { name: 'suivi-des-departs-form', params: { id: this.$route.params.id } } } : {}
    ])
    this.$store.commit('pages/setPageTitle', this.$t('processes.title.suivi_des_departs'))

    this.$store.commit('pages/setTabs', [
      { name: 'suivi-des-departs-form', label: 'Informations', to: { name: 'suivi-des-departs-form' }, params: { id: this.$route.params.id } }
    ])
    if (this.$route.params.id) {
      this.formData.id = this.$route.params.id
    }
  },

  preFetch ({ store, currentRoute, previousRoute, redirect, ssrContext }) {
    if (currentRoute.params.id) {
      store.dispatch('processes/fetchProcess', { id: currentRoute.params.id, type: 'depart' }).then(() => {
        store.dispatch('processes/fetchFormFields', 'depart')
      })
    } else {
      store.dispatch('processes/fetchFormFields', 'depart')
    }
  },
  beforeDestroy () {
    this.$store.commit('processes/setCurrentProcess', null)
  },
  methods: {
    createLinkedProcess (process) {
      this.$store.dispatch('processes/createLinkedProcess', { linkedProcess_type: process, process_id: this.$route.params.id, process: 'depart' }).then(datas => {
        if (!this.formData.related_objects) this.formData.related_objects = []
        datas.forEach(linkedObj => {
          this.relatedProcessLinked.push({
            id: linkedObj.id,
            type: process,
            wallets: linkedObj.wallets,
            agents: linkedObj.agents,
            actions: [
              { label: this.$t(`processes.unlink_${process}`), payload: { name: `unlink-${process}`, params: { id: linkedObj.id } } }
            ]
          })
          this.formData.related_objects.push({
            model_type: process,
            model_id: linkedObj.id
          })
        })
      })
    },
    finishProcess () {
      this.formData.statut = { label: 'TERMINE', value: 'TERMINE' }
      this.save()
    },
    toggleCollapsible (event) {
      let originalElement = event.target || event.srcElement || event.originalTarget
      if (originalElement.classList.contains('arrow')) {
        originalElement.parentElement.classList.toggle('rotate')
        originalElement.parentElement.nextSibling.classList.toggle('display-none')
      } else {
        originalElement.classList.toggle('rotate')
        originalElement.nextSibling.classList.toggle('display-none')
      }
    },
    onSave ({ id }) {
      this.isUpdate = true
      if (!this.$route.params.id) this.$router.push({ name: 'suivi-des-departs-form', params: { id } })
    },
    copyCour () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    copyPrepa () {
      const text = 'Ito ny texte'
      let el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
    },
    updateWallet (wallets) {
      console.log(wallets)
      let walletMapped = wallets.map(wallet => ({
        model_type: 'wallet',
        model_id: wallet.id
      }))

      if (!this.formData.related_objects) this.formData.related_objects = []
      // Remove all wallet in related object
      this.formData.related_objects = this.formData.related_objects.filter(related => related.model_type !== 'wallet')
      this.formData.related_objects.push(...walletMapped)
    },
    onSelectLinkedObj (obj) {
      console.log(obj)
      this.linkedAgent = {
        label: obj.value.label,
        id: obj.value.value,
        actions: [
          { label: this.$t('tasks.remove_linked_object'), payload: { name: 'model-detach', params: { id: obj.value.value } } }
        ]
      }
    },
    onMenuClick ({ name, params }) {
      this.hasChanged = true
      if (name === 'model-detach') {
        this.linkedAgent = null
        this.searchBoxActivated = false
      } else if (name === 'delete-link') {
        this.relatedLinks = this.relatedLinks.filter(link => link.id !== params.id)
      } else if (name === 'sujet-detach') {
        this.sujets = this.sujets.filter(sujet => sujet.id !== params.id)
      } else if (name === 'unlink-recrutement') {
        this.relatedSuiviDeRecrutement = this.relatedSuiviDeRecrutement.filter(suiviDeRecrutement => suiviDeRecrutement.id !== params.id)
      } else if (name === 'unlink-suiviIc') {
        this.relatedSuiviIc = this.relatedSuiviIc.filter(suiviIc => suiviIc.id !== params.id)
      }
    },
    addLink () {
      if (this.link.titre === '' || this.link.lien === '') return
      let tempId = 0
      if (!this.relatedLinks) {
        this.relatedLinks = []
      } else if (this.relatedLinks.length) {
        console.log(this.relatedLinks)
        tempId = Math.max.apply(Math, this.relatedLinks.map(link => link.id)) + 1
        console.log('ato', tempId)
      }

      // Remove " first before adding
      let copiedObject = Object.assign({}, this.link)
      copiedObject.id = tempId
      copiedObject.lien = copiedObject.lien.replace(/"/g, '')
      copiedObject.actions = [{ label: this.$t('tasks.remove_linked_object'), payload: { name: 'delete-link', params: { id: tempId } } }]

      this.relatedLinks.push(copiedObject)
      this.link.titre = ''
      this.link.lien = ''
      this.isLinksFormOpen = false
    },

    destroy () {
      this.$confirm(this.$t('processes.delete_process_confirm'), this.$t('form.delete')).onOk(() => {
        this.pending = true
        this.$store.dispatch('processes/deleteProcesses', { type: 'depart', id: this.$route.params.id }).then(() => {
          this.notifySuccess('processes.delete_success')
          this.$router.go(-1)
        }).catch(this.$notifyError)
          .finally(() => { this.pending = false })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
  .add_process_on_required_label
    color $process
  .bloc-item
    position relative
  .toggler-collapse
    border 1px solid $process
    border-radius 100%
    width 20px
    height 20px
    position absolute
    right 0;
    top 7px;
    cursor pointer
  .arrow
    border-top 1px solid $process
    border-left 1px solid $process
    transform rotate(45deg)
    width 6px
    height 6px
    margin-left 6px
    margin-top 7px
  .display-none
    display none
  .rotate
    transform rotate(180deg)
</style>
